$width-sm: 576px;
$width-md: 768px;
$width-lg: 992px;
$width-xl: 1200px;

.container {
  padding-top: 2em;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
li,
div {
  font-family: "Lato", Helvetica, sans-serif;
}

h2 {
  color: $gray;
  font-size: 1.1em;
  letter-spacing: 0.1em;
  padding-bottom: 3px;
  border-bottom: 1px dotted $gray;
  margin-bottom: 1.5em;
}

#avatar {
  display: inline-block;
  width: 80px;
  height: 80px;
  background: url("../img/avatar@1x.jpg") no-repeat;
  background-size: cover;
  border-radius: 50%;
  margin-right: 1em;
  vertical-align: middle;
}

#me {
  vertical-align: middle;
  display: inline-block;
  color: $blue;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-weight: 700;
  line-height: 1.2em;
}

#my-title {
  div {
    &:first-child {
      height: 2.5em;
      width: 0.1em;
      background-color: $gray;
      transform: rotate(20deg);
      position: absolute;
      top: 3em;
      left: 11.5em;
    }
    &:last-child {
      line-height: 1.2em;
      font-size: 0.7em;
      letter-spacing: 0.1em;
      color: $moderate-gray;
      position: absolute;
      top: 5em;
      left: 18em;
    }
  }
}

ul {
  list-style: none;
  padding: 0;
}

p {
  color: $black;
  font-size: 0.875em;
  line-height: 1.5em;

  span {
    color: $gray;
  }
}

a {
  color: $black;
  text-decoration: none;
  padding-bottom: 1px;
  border-bottom: 1px dashed transparent;

  &:hover {
    color: $black;
    text-decoration: none;
    border-bottom: 1px dashed $blue;
  }
}

.section-partial {
  margin-bottom: 3em;
  color: $black;

  &:last-child {
    margin-bottom: 0;
  }
}

#aboutMe {
  margin-bottom: 3em;

  @media screen and (max-width: $width-lg) {
    margin-bottom: 1em;
    .col-lg-4 {
      text-align: right;
    }
  }

  p {
    margin-bottom: 0.875em;
  }

  #contact-details {
    margin-bottom: 0;
    li {
      .contact-means {
        display: inline-block;
        margin-bottom: 0.75em;
        img {
          width: 1em;
          height: 1em;
          padding-bottom: 0.2em;
          transition: width 150ms ease-in, height 150ms ease-in;
        }
        .contact-desc {
          display: inline;
          margin-left: 0.5em;
        }
      }
    }

    @media screen and (max-width: $width-lg) {
      margin-top: 2em;
      display: inline-flex;

      a {
        &:hover {
          border: none;
        }
      }

      img {
        width: 1.2em !important;
        height: 1.2em !important;

        margin: 0 1em;
      }

      .contact-desc {
        display: none !important;
      }
    }
  }
}

#resume {
  ul {
    li {
      p {
        padding-bottom: 1em;
        margin: 0;
        font-size: 0.8em;
        span {
          color: $moderate-gray;
        }
      }
    }
  }

  > div {
    &:first-child {
      margin-bottom: 3em;
    }
  }
}

#experience {
  @media screen and (min-width: $width-lg) {
    padding-right: 3em;
  }

  .section-partial {
    > p {
      margin-bottom: 0;
    }
  }

  ul {
    padding: 0.5em;
    li {
      padding-left: 1em;
      border-left: 1px dotted $blue;

      .experience-dots {
        display: inline-block;
        position: relative;
        right: 1.4em;
        top: 1.3em;
        width: 0.7em;
        height: 0.7em;
        background-color: $white;
        border: 0.1em solid $blue;
        border-radius: 50%;
      }
    }
  }
}

#education,
#skills {
  margin-bottom: 3em;
  .section-partial {
    margin-bottom: 1em;
    p {
      span {
        line-height: 2em;
        &.course {
          line-height: 1em;
          color: $black;
        }
        &.subject {
          line-height: 1em;
        }
      }
    }
  }
}

#skills {
  p {
    margin-bottom: 0.2em;
  }
  ul {
    li {
      font-size: 0.8em;
      display: inline-table;
      color: $moderate-gray;

      &:after {
        content: "•";
        margin-right: 0.5em;
        margin-left: 0.5em;
        font-size: 0.2em;
        color: $black;
      }
      &:last-child {
        &:after {
          content: none;
        }
      }
    }
  }
}
